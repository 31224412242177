import { axiosApiInstance } from "@/services/base";

class BrandService {
  list() {
    return axiosApiInstance.get(`/brands`);
  }

  create(payload) {
    return axiosApiInstance.post(`/brands`, payload);
  }

  delete(id) {
    return axiosApiInstance.delete(`/brands/${id}`);
  }

  resort(payload) {
    return axiosApiInstance.put(`/brands/resort`, payload);
  }

  update(id, payload) {
    return axiosApiInstance.put(`/brands/${id}`, payload);
  }
}

export default new BrandService();
