<template>
  <div>
    <BlockUI :blocked="blocked" :fullScreen="true">
      <Card class="main-content">
        <template #content>
          <div class="toolbar">
            <div class="toolbar-start">
              <!-- Brand -->
              <KBaseDropdown
                v-model="selectedBrandId"
                :options="optionBrands"
                @change="loadProducts"
              />

              <!-- Priority -->
              <KBaseDropdown
                v-model="selectedPriorityId"
                :options="optionPriorities"
                @change="loadProducts"
              />

              <!-- Status -->
              <KBaseDropdown
                v-model="selectedStatusId"
                :options="optionStatuses"
                @change="loadProducts"
              />
            </div>
            <div class="toolbar-end">
              <router-link :to="{ name: 'NewProduct' }">
                <Button>Add New Product</Button>
              </router-link>
            </div>
          </div>
          <DataTable :value="dataSource" dataKey="id" responsiveLayout="scroll">
            <Column
              v-for="col of columns"
              :field="col.field"
              :header="col.header"
              :key="col.field"
              :style="col.style"
              sortable
            >
              <template #body="{ field, data, index }">
                <template v-if="field == 'image_url'">
                  <img
                    width="50"
                    style="vertical-align: middle"
                    :src="data.image_url"
                  />
                </template>

                <template v-else-if="field == 'name'">
                  <a href="#" @click="setIndexAndEditProduct(index, $event)">
                    <div>{{ data.name }}</div>
                  </a>
                </template>

                <template v-else>
                  {{ colFormatter(field, data[field]) }}
                </template>
              </template>
            </Column>
            <Column
              :exportable="false"
              style="min-width: 8rem"
              columnKey="action"
            >
              <template #header>
                <img src="@/assets/icons/Setting.png" alt="setting" />
              </template>
              <template #body="{ index }">
                <div>
                  <Button
                    @click="toggle(index, $event)"
                    class="p-button-text"
                    style="padding: 0"
                  >
                    <img src="@/assets/icons/More.png" alt="more" />
                  </Button>
                  <OverlayPanel
                    :ref="
                      (el) => {
                        ops[index] = el;
                      }
                    "
                  >
                    <a href="#" @click="editProduct">
                      <div class="menuItem">Edit</div>
                    </a>
                    <a href="#" @click="deleteProduct">
                      <div class="menuItem">Delete</div>
                    </a>
                  </OverlayPanel>
                </div>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </BlockUI>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Card from "primevue/card";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import OverlayPanel from "primevue/overlaypanel";

import KBaseDropdown from "@/components/KBaseDropDown";
import ProductFilterDropDownOptions from "@/components/Products/ProductFilterDropDownOptions";

import BrandService from "@/services/brand_service";
import ProductService from "@/services/product_service";
import { formatNumber, formatPercent } from "@/utils/formatter";

export default defineComponent({
  components: {
    BlockUI,
    Button,
    Card,
    Column,
    DataTable,
    OverlayPanel,

    KBaseDropdown,
  },

  setup() {
    onMounted(() => {
      loadProducts();

      BrandService.list().then(({ data }) => {
        optionBrands.value = ProductFilterDropDownOptions.brands(
          data.brands.map((brand) => ({ label: brand.name, value: brand.id }))
        );
        selectedBrandId.value = optionBrands.value[0].value;
      });
      ProductService.listPriorities().then(({ data }) => {
        optionPriorities.value = ProductFilterDropDownOptions.priorities(
          data.priorities.map((priority) => ({
            label: priority.name,
            value: priority.id,
          }))
        );
        selectedPriorityId.value = optionPriorities.value[0].value;
      });
      ProductService.listStatuses().then(({ data }) => {
        optionStatuses.value = ProductFilterDropDownOptions.statuses(
          data.statuses.map((status) => ({
            label: status.name,
            value: status.id,
          }))
        );
        selectedStatusId.value = optionStatuses.value[0].value;
      });
    });

    const router = useRouter();
    const blocked = ref(false);
    const confirm = useConfirm();
    const toast = useToast();

    const ops = ref([]);

    const rowIndex = ref();
    const toggle = (index, event) => {
      ops.value[index].toggle(event);
      rowIndex.value = index;
    };

    const selectedBrandId = ref();
    const selectedPriorityId = ref();
    const selectedStatusId = ref();

    const selectedProduct = ref();
    const products = [];

    const optionBrands = ref(ProductFilterDropDownOptions.brands());
    const optionPriorities = ref(ProductFilterDropDownOptions.priorities());
    const optionStatuses = ref(ProductFilterDropDownOptions.statuses());

    const dataSource = ref();

    const columns = ref([
      {
        field: "priority",
        header: "Priority",
      },
      {
        field: "image_url",
        header: "Photo",
      },
      {
        field: "name",
        header: "Name",
        style: "min-width: 14rem",
      },
      {
        field: "brand",
        header: "Brand",
      },
      {
        field: "status",
        header: "Status",
      },
      {
        field: "note",
        header: "Note",
      },
    ]);

    const loadProducts = () => {
      blocked.value = true;

      let brand_id = selectedBrandId.value;
      let priority_id = selectedPriorityId.value;
      let status_id = selectedStatusId.value;
      let query = {};
      if (brand_id > 0) query["q[brand_id_eq]"] = brand_id;
      if (priority_id > 0) query["q[priority_id_eq]"] = priority_id;
      if (status_id > 0) query["q[status_id_eq]"] = status_id;

      ProductService.listProducts(query)
        .then(({ data }) => {
          dataSource.value = data.products;
        })
        .finally(() => (blocked.value = false));
    };

    const editProduct = (event) => {
      event.preventDefault();

      let id = dataSource.value[rowIndex.value].id;

      router.push({
        name: "EditProduct",
        params: { id: id },
      });
    };
    const deleteProduct = (event) => {
      event.preventDefault(event);

      let id = dataSource.value[rowIndex.value].id;

      confirm.require({
        message: `Are you sure you want to delete?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Delete",
        rejectLabel: "Cancel",
        accept: () => {
          blocked.value = true;

          ProductService.deleteProduct(id)
            .then(() => {
              toast.add({
                severity: "success",
                summary: "Product deleted",
                life: 3000,
              });
              loadProducts();
            })
            .catch((e) => {
              console.error(e);
              toast.add({
                severity: "error",
                summary: "Product not deleted",
                life: 3000,
              });
            })
            .finally(() => {
              blocked.value = false;
            });
        },
      });
    };

    const colFormatter = (field, value) => {
      const col = columns.value.filter((col) => col.field == field)[0];
      return col?.formatter ? col.formatter(value) : value;
    };

    const setIndexAndEditProduct = (index, event) => {
      rowIndex.value = index;
      editProduct(event);
    };

    return {
      blocked,
      ops,
      toggle,

      selectedBrandId,
      selectedPriorityId,
      selectedStatusId,

      selectedProduct,
      products,
      loadProducts,

      optionBrands,
      optionPriorities,
      optionStatuses,

      dataSource,
      columns,
      colFormatter,
      editProduct,
      deleteProduct,
      setIndexAndEditProduct,

      formatNumber,
      formatPercent,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep tr.p-datatable-row-expansion {
  background-color: #f8f9fa;
}

.main-content {
  margin-top: 1rem;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .toolbar-start {
    display: flex;
    align-items: flex-start;
  }

  .toolbar-end {
    display: flex;
    align-items: flex-end;
  }

  margin-bottom: 1rem;
}

.menuItem {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  margin-left: -1rem;
  margin-right: -1rem;

  &:hover {
    background: #dddddd;
  }
}
</style>
