import { axiosApiInstance } from "@/services/base";

class ProductService {
  listProducts(params) {
    return axiosApiInstance.get(`/product/products`, { params: params });
  }

  showProduct(id) {
    return axiosApiInstance.get(`/product/products/${id}`);
  }

  createProduct(payload) {
    return axiosApiInstance.post(`/product/products`, payload);
  }

  updateProduct(id, payload) {
    return axiosApiInstance.put(`/product/products/${id}`, payload);
  }

  deleteProduct(id) {
    return axiosApiInstance.delete(`/product/products/${id}`);
  }

  // Priority
  listPriorities() {
    return axiosApiInstance.get(`/product/priorities`);
  }

  createPriority(payload) {
    return axiosApiInstance.post(`/product/priorities`, payload);
  }

  deletePriority(id) {
    return axiosApiInstance.delete(`/product/priorities/${id}`);
  }

  resortPriority(payload) {
    return axiosApiInstance.put(`/product/priorities/resort`, payload);
  }

  updatePriority(id, payload) {
    return axiosApiInstance.put(`/product/priorities/${id}`, payload);
  }

  // Status
  listStatuses() {
    return axiosApiInstance.get(`/product/statuses`);
  }

  createStatus(payload) {
    return axiosApiInstance.post(`/product/statuses`, payload);
  }

  deleteStatus(id) {
    return axiosApiInstance.delete(`/product/statuses/${id}`);
  }

  resortStatus(payload) {
    return axiosApiInstance.put(`/product/statuses/resort`, payload);
  }

  updateStatus(id, payload) {
    return axiosApiInstance.put(`/product/statuses/${id}`, payload);
  }

  // Listing Version
  listListingVersions(productId) {
    return axiosApiInstance.get(`/product/${productId}/listing_versions`);
  }

  createListingVersion(productId, payload) {
    return axiosApiInstance.post(
      `/product/${productId}/listing_versions`,
      payload
    );
  }

  updateListingVersion(productId, id, payload) {
    return axiosApiInstance.put(
      `/product/${productId}/listing_versions/${id}`,
      payload
    );
  }

  deleteListingVersion(productId, id) {
    return axiosApiInstance.delete(
      `/product/${productId}/listing_versions/${id}`
    );
  }
}

export default new ProductService();
