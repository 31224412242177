const brands = [
  {
    label: "All Brands",
    value: 0,
  },
];

const priorities = [
  {
    label: "All Priorities",
    value: 0,
  },
];

const statuses = [
  {
    label: "All Status",
    value: 0,
  },
];

class DropDownOptions {
  brands(options = []) {
    return brands.concat(options);
  }

  priorities(options = []) {
    return priorities.concat(options);
  }

  statuses(options = []) {
    return statuses.concat(options);
  }
}

export default new DropDownOptions();
